$.widget("custom.tabBar", {
    options: {},

    _create: function () {
        var self = this;
        var tabSlider = self.element.find('.tabSlider');
        var doSwipe = $('<span class="doSwipe"></span>');

        if (ma.getDevice('isTouchDevice')) {
            self.element.append(doSwipe);
        }

        tabSlider.on({
            'touchstart': function () {
                doSwipe.fadeOut();
            }
        });

        tabSlider.find('.tab_element').click(function () {
            doSwipe.fadeOut();

            var tabWidth = $(this).outerWidth();
            var count = tabSlider.find('.tab_element').index($(this));
            var offset = tabWidth * count;

            tabSlider.animate({
                scrollLeft: offset - 50
            }, 250);
        });
    }
});
;
//debugfunction, schreibt werte in die Konsole und auch in ein speziell erzeugtes div
function debug(args, replace){
    var indicator = '';
    if(replace == undefined || replace == null || replace == false){
        replace = false;
    }else{
        if(typeof(replace) == 'string' ){
            indicator = replace+': ';
            replace = false;
        }else{
            replace = true;
        }
    }
    var debugOut = null;
    var body = document.body;
    if(document.getElementById('out') == null ){
        debugOut = document.createElement('div');
        debugOut['id']='out';
        debugOut['style']['background'] = 'lime';
        debugOut['style']['border'] = '3px solid red';
        debugOut['style']['padding'] = '10px';
        debugOut['style']['position'] = 'fixed';
        debugOut['style']['top'] = '100px';
        debugOut['style']['right'] = '50px';
        debugOut['style']['zIndex'] = '999999999';
        debugOut['style']['fontSize'] = '11px';
        debugOut['style']['color'] = '#000000';
        debugOut['style']['overflow'] = 'auto';
        debugOut['style']['maxWidth'] = '90%';
        debugOut['style']['maxHeight'] = '80%';
        body.appendChild(debugOut);
    }

    if(window.console){
        if($.type(console) == 'object'){
            if(indicator.length > 0){
                console.log('▼'+indicator+'▼');
            }
            console.log(args);
        }
    }
    debugOut = document.getElementById('out');
    if(replace===true){
        debugOut.innerHTML = indicator + args+"<br />";
    }else{
        debugOut.innerHTML = debugOut.innerHTML + indicator +args+"<br />";
    }

} //ende debug
try{
	if (typeof(mobileAction) === 'undefined') {
		//mobileAction als Object vorbereiten
		mobileAction = function ($) {
			//private vars setzen
			if(typeof ($) === 'undefined'){
				var $ = window.jQuery;
			}
			var self = this;
			var lazyCount = 0;
			//Objektvariablen füllen
            self.lazyResizeTimer        = null;
			self.debug                  = false;
            self.bootstrapVersion       = 4;
            self.isInitialized          = false;
			self.activeBreakPoint       = 'xs';
			self.bsIndicator            = null;
			self.objimg                 = {};
			self.device                 = null;
			self.isFirstEvent	 		= true;
            self.win                    = $(window);
			self.isFirstBreakPointEvent = {
				xs : true,
				sm : true,
				md : true,
				lg : true,
				xl : true
			};
			self.breakpoints = ['xs','sm','md','lg','xl'];
			self.events                 = [
				'breakpoint.ma',
				'firstbreakpoint.ma',
				'scriptlazyloadready.ma',
				'view_portrait.ma',
				'view_landscape.ma',
				'view_change_landscape.ma',
				'view_change_portrait.ma',
				'orientation_changed.ma',
				'fullyloaded.ma'
			];

			if(self.debug){
				$(document).on(self.events.join(' '), function(e){
					debug(e.status + ' : ' + e.type);
				});
			}
			self.aktScrollPosition = 0;
			self.scrollDirection = 'down';
			//funktionen, die direkt verfügbar sein müssen:
            self.win.scroll(function(){
                var stpos = self.win.scrollTop();
                if(stpos > self.aktScrollPosition){
                    self.scrollDirection = 'down';
                }else if(stpos < self.aktScrollPosition){
                    self.scrollDirection = 'up';
                }
                self.aktScrollPosition = stpos;
            })
            if(self.win.scrollTop() != 0){
                self.win.trigger('scroll');
            }
			//breakpoint-Indikatoren im DOM verfügbar machen
			self.prepareBreakIndicator = function(){
                if(self.bootstrapVersion < 4){
                    self.breakpoints.pop();
                    $('body').append('<div id="bsbpindic"><div class="visible-xs" id="xs"></div><div class="visible-sm" id="sm"></div><div class="visible-md" id="md"></div><div class="visible-lg" id="lg"></div></div>');
                }else{
                    $('body').append('<div id="bsbpindic"><div class="d-block d-sm-none" id="xs"></div><div class="d-none d-sm-block d-md-none" id="sm"></div><div class="d-none d-md-block d-lg-none" id="md"></div><div class="d-none d-lg-block d-xl-none" id="lg"></div><div class="d-none d-none d-xl-block" id="xl"></div></div>');
                }
				self.bsIndicator = $('#bsbpindic');
				self.getBreakpoint();
				self.win.resize(function(){
					self.getBreakpoint();
					clearTimeout(self.lazyResizeTimer);
                    self.lazyResizeTimer = setTimeout(function(){
                        $(document).trigger('resize.ma');
                    },100);
				});
			};
			//Aktuellen Breakpoint bzw aktuelle ansicht ermitteln
			self.getBreakpoint = function(){
				var activeBreak = self.bsIndicator.find('div:visible').get(0).id;
				if(activeBreak != self.activeBreakPoint){
					self.activeBreakPoint = activeBreak;
					$.event.trigger({
						type : 'breakpoint.ma',
						status : self.activeBreakPoint,
						isfirst : self.isFirstEvent
					});
					self.isFirstEvent = false;
					if(self.isFirstBreakPointEvent[self.activeBreakPoint]){
						$.event.trigger({
							type : 'firstbreakpoint.ma',
							status : self.activeBreakPoint,
							firsttimebreak : self.isFirstBreakPointEvent[self.activeBreakPoint]
						});
                        if(self.debug){
                            debug(self.isFirstBreakPointEvent[self.activeBreakPoint], 'isFirstBreakPointEvent');
                            debug(self.activeBreakPoint, 'activeBreakPoint');
                        }
						self.isFirstBreakPointEvent[self.activeBreakPoint] = false;
					}


				}
				return self.activeBreakPoint;
			};
			//javascript-Abhängigkeiten nachladen
			self.lazyLoad = function(src,id){
				var lazyScriptTag = document.createElement('script');
				lazyScriptTag.id=id;
				lazyScriptTag.src = src; // set the src attribute
				//lazyScriptTag.async = true;
				lazyScriptTag.onload = function(){
					lazyCount++;
					$.event.trigger({
						type : 'scriptlazyloadready.ma',
						script : id,
						count : lazyCount
					});
				}

				var head = document.getElementsByTagName('head')[0];
				head.appendChild(lazyScriptTag);
			};

			// registers swipe for obj, allows scrolldirection as mentioned and needs an object with callbacks matched to the detected direction
			// mobileAction.registerSwipe(obj, allowpageScrollDirection, callbackObj)
			self.registerSwipe = function(obj, allowpageScrollDirection, callbackObj){
				if(typeof(allowpageScrollDirection) === 'undefined'){
					allowpageScrollDirection = 'vertical';
				}
				obj.swipe( {
					swipe:function(event, direction, distance, duration, fingerCount, fingerData) {
						event.stopPropagation();
						if(self.debug === true){
							debug(distance + ' : ' +direction);
						}
						if($.isFunction(callbackObj)){
							callbackObj();
						}else{
							if(typeof(callbackObj[direction]) != 'undefined' ){
								if($.isFunction(callbackObj[direction])){
									callbackObj[direction]();
								}
							}
						}
					},
					threshold:90,
					allowPageScroll: allowpageScrollDirection,
					excludedElements : ["label, button, input, select, textarea, .noSwipe"]
				});
			};

			self.getDeviceInfo = function(){
				return self.device;
			};

			//alle zur Verfügung stehenden Funktionen hier registrieren
			self.preloadImage = function(src){
				if(self.objimg[src] == undefined){
					self.objimg[src] = new Image();
					self.objimg[src].src=src;
				}
			};
			matchmedia = function(){
				// Find matches
				var mql = window.matchMedia("(orientation: portrait)");
				self.getBreakpoint();
				// If there are matches, we're in portrait
				if(mql.matches) {
					$.event.trigger({
						type : 'view_portrait.ma',
						status : self.activeBreakPoint,
					});
					self.orientation = 'portrait';
				}else {
					$.event.trigger({
						type : 'view_landscape.ma',
						status : self.activeBreakPoint,
					});
					self.orientation = 'landscape';
				}

				// Add a media query change listener
				mql.addListener(function(m) {
					if(m.matches) {
						self.getBreakpoint();
						$.event.trigger({
							type : 'view_change_portrait.ma',
							status : self.activeBreakPoint,
							orientation: 'portrait'
						});
						$.event.trigger({
							type : 'orientation_changed.ma',
							status : self.activeBreakPoint,
							orientation: 'portrait'
						});
						self.orientation = 'portrait';
					}else {
						self.getBreakpoint();
						$.event.trigger({
							type : 'view_change_landscape.ma',
							status : self.activeBreakPoint,
							orientation: 'landscape'
						});
						$.event.trigger({
							type : 'orientation_changed.ma',
							status : self.activeBreakPoint,
							orientation: 'landscape'
						});
						self.orientation = 'landscape';
					}
				});
				return 'matchmedia returns anything :P';
			};
			self.getDevice = function(key){
				if(typeof(key) != 'undefined'){
					if(typeof(self.device[key]) != 'undefined'){
						return self.device[key];
					}else{
						return self.device;
					}
				}else{
					return self.device;
				}
			}
			self.generateOutputForProperty = function(propertyname, identifier) {
				var ptype   = typeof(self[propertyname])
				var ret     = '';
				if(typeof(identifier) == 'undefined'){
					identifier = propertyname;
				}
				ret += identifier;
				if(ptype != 'undefined'){
					if(ptype == 'null'){
						ret += ': NULL';
					}else{
						if(ptype == 'object'){
							if($.isArray(self[propertyname])){
								ret += ': Array [size: '+self[propertyname].length+'] | ' +( self[propertyname].length==0 ? 'empty Array!': self[propertyname].join(',') );
							}else if($.isPlainObject(self[propertyname])){
								ret += ': Object | ';
								if($.isEmptyObject(self[propertyname])){
									ret += 'empty Object!';
								}else{
									for(var e in self[propertyname]){
										ret += (e + ': '+self[propertyname][e]+' | ');
									}
								}
							}
						}else{
							ret += ': ' + self[propertyname]
						}
					}
				}else{
					ret += ': undefined';
				}
				return ret;
			};

			self.breakpointDown  = function(breakpoint,e){
			    var ret = false;
			    var usableBreakPoints = self.breakpoints;
			    if(typeof e != 'undefined')
                {
                    if(e.namespace == 'ma'){
                        var cutPosition = usableBreakPoints.indexOf(breakpoint)+1;
                        usableBreakPoints = usableBreakPoints.slice(0, cutPosition);
                        if(usableBreakPoints.indexOf(e.status) != -1){
                            ret = true;
                        }
                    }
                }
                return ret;
            };
            self.breakpointUp  = function(breakpoint,e){
                var ret = false;
                var usableBreakPoints = self.breakpoints;
                if(typeof e != 'undefined')
                {
                    if(e.namespace == 'ma'){
                        var cutPosition = usableBreakPoints.indexOf(breakpoint);
                        usableBreakPoints = usableBreakPoints.slice(cutPosition, usableBreakPoints.length);
                        if(usableBreakPoints.indexOf(e.status) != -1){
                            ret = true;
                        }
                    }
                }
                return ret;
            };
            self.breakpointOnly  = function(breakpoint,e){
                var ret = false;
                var usableBreakPoints = self.breakpoints;
                if(typeof e != 'undefined')
                {
                    if(e.namespace == 'ma'){
                        if(e.status == breakpoint){
                            ret = true;
                        }
                    }
                }
                return ret;
            };

			self.htmlOutput = function(){
				var ret = [];
				var outputProps = [
					'debug',
					'isInitialized',
					'activeBreakPoint',
					'bsIndicator',
					'objimg',
					'device',
					'isFirstEvent',
					'isFirstBreakPointEvent',
					'events'
				];
				for(var i=0;i < outputProps.length; i++){
					ret.push(self.generateOutputForProperty(outputProps[i]));
				}
				return ret.join('<br>');
			};

			//Aufrufen der notwendigen Abhängigkeiten
			self.prepareBreakIndicator();
			matchmedia();

			//rudimentäre Information über das Endgerät bereitstellen
			self.device = {
				fallbackDevice  : true,
				isTouchDevice   : (typeof(window.ontouchstart)==='undefined' ? false:true),
				isMobile        : (navigator.userAgent.match(/(iPhone|iPod|iPad|blackberry|android|Kindle|htc|lg|midp|mmp|mobile|nokia|opera mini|palm|pocket|psp|sgh|smartphone|symbian|treo mini|Playstation Portable|SonyEricsson|Samsung|MobileExplorer|PalmSource|Benq|Windows Phone|Windows Mobile|IEMobile|Windows CE|Nintendo Wii)/i)),
				status          : self.activeBreakPoint,
				orientation     : self.orientation,
				width           : window.screen.width,
				height          : window.screen.height
			}

			//wurde device.js geladen ist mx_device gefüllt
			if(typeof (window['mx_device']) != 'undefined'){
				self.device = window['mx_device'];
			}else{
				try{
					$(document).on('scriptlazyloadready.ma', function(e){
						if(e.script == 'devicejs'){
							$(document).on('deviceidentified.dv', function(){
								self.device = window['mx_device'];
								//debug('device.js loaded async');
							})
						}
					});
					self.lazyLoad('/js/device.js','devicejs');
				}catch(e){
					//Script nich abstürzen lassen :)
				}
			}

			//private function
			function isObjectInViewport(elm){
                var inViewPort = false;
                var win = self.win;
                var bounds = elm.offset();
                var topModifier = 0.25;
                var bottomModifier = 0.75;
                if(self.activeBreakPoint=='xs'){
                    topModifier = 1;
                    bottomModifier = 1;
                }
                var viewport = {
                    top : win.scrollTop(),
                    topModded : (win.scrollTop() - (elm.outerHeight()*topModifier)),
                    left : win.scrollLeft()
                };

                viewport.right = viewport.left + win.width();
                viewport.bottom = viewport.top + win.height();
                bounds.right = bounds.left + elm.outerWidth();
                bounds.bottom = (bounds.top + (elm.outerHeight()*bottomModifier));

                if(self.debug) {
                    debug('viewport top: ' + viewport.top);
                    debug(self.scrollDirection);
                    debug('bounds.top: ' + bounds.top);
                    debug('viewport.top: ' + viewport.top);
                    debug('viewport.topModded: ' + viewport.topModded);
                    debug('viewport.bottom: ' + viewport.bottom);
                    debug('bounds.bottom: ' + bounds.bottom);
                }

                //scrolldirection abfragen und dann bei "up" viewport.topModded nehmen, bei "down" viewport.top
                if(self.scrollDirection == 'down'){
                    if(self.activeBreakPoint=='xs'){
                        if(self.aktScrollPosition >= bounds.top-(win.height()/2)){
                            inViewPort = true;
                        }
                    }else{
                        if((viewport.bottom >= bounds.bottom && viewport.top <= bounds.top)   ){
                            inViewPort = true;
                        }
                    }
                }else{
                    if(self.activeBreakPoint=='xs'){
                        if(self.aktScrollPosition <= (bounds.bottom - (win.height()*0.8))){
                            inViewPort = true;
                        }
                    }else{
                        if((viewport.bottom >= bounds.bottom && viewport.topModded <= bounds.top)   ){
                            inViewPort = true;
                        }
                    }
                }
                return inViewPort;
            }

			self.isScrolledIntoView = function(elm, callback){

                //ensure scrollhandler is bound only once
                if(typeof(elm.data('scrollattached')) == 'undefined'){
                    elm.data('scrollattached', false);
                }
                //not in viewport? bind scroll
				if(isObjectInViewport(elm) == false){
					if(elm.data('scrollattached') == false){
						self.win.scroll(function(){
							elm.data('scrollattached', true);
							self.isScrolledIntoView(elm, callback);
						});
					}
				}else {
                    if ($.isFunction(callback)) {
                        callback();
                    }
                }
			};

			self.r_append = function(obj){
                return obj.each(function(){

                    var $self = $( this ),
                        att = "data-rap",
                        $parent = $self.parent(),
                        attval = $parent.attr( att ),
                        $set = $( "["+ att +"='" + attval + "']" );

                    function moveIntoPlaceHolder(){
                        if( $parent.is(':hidden') ){
                            var found = 0;
                            $set.each(function(){
                                var possPlaceHolder = $(this);
                                if( !possPlaceHolder.is(':hidden') && !found ){
                                    $self.appendTo( possPlaceHolder );
                                    found++;
                                    $parent = possPlaceHolder;
                                }
                            });
                        }
                    }
                    moveIntoPlaceHolder();
                    $(document).on('breakpoint.ma', function(e){
                        if(e.namespace == 'ma'){
                            moveIntoPlaceHolder();
                        }
                    });
                });
            };

			//mobileAction initialisiert.
			self.isInitialized = true;
			$.event.trigger({
				type        : 'fullyloaded.ma',
				objInstance : self,
				status : 'mobileAction'
			});
		}
	} else {
		throw {
			name:        "VAR-Error",
			level:       "Show Stopper",
			message:     "Variable already defined.\nPlease check the integrated Javascript.",
			htmlMessage: "<h1>Variable already defined. Please check the integrated Javascript</h1>",
			toString:    function(){return self.name + ": " + self.message;}
		};
	}
}catch(e){
	if(typeof (console) == 'object'){
		console.error(e.message);
	}
}

export default mobileAction;

'use strict';/*!
 * This is a `i18n` language object.
 *
 * Ukrainian
 *
 * @author
 *   Dmitry Krekota (dmitry.krekota@gmail.com)
 *
 * @see core/i18n.js
 */
(function (exports) {
	if (exports.uk === undefined) {
		exports.uk = {
			'mejs.plural-form': 7,			
			'mejs.download-file': 'Завантажити файл',			
			'mejs.install-flash': 'Flash Player у вашому браузері не встановлений або відключений. Будь ласка включіть Flash Player або скачайте останню версію із https://get.adobe.com/flashplayer/',			
			'mejs.fullscreen': 'Повноекранний режим',			
			'mejs.play': 'Пуск',
			'mejs.pause': 'Пауза',			
			'mejs.time-slider': 'Повзунок часу',
			'mejs.time-help-text': 'Використовуйте ліву/праву клавіші зі стрілками, щоб переміститися на одну секунду, або клавіші вверх/вниз, щоб переміститися на десять секунд.',
			'mejs.live-broadcast' : 'Пряма трансляція',			
			'mejs.volume-help-text': 'Використовуйте клавіші зі стрілками вверх/вниз, щоб збільшити або зменшити звук.',
			'mejs.unmute': 'Включити звук',
			'mejs.mute': 'Відключити звук',
			'mejs.volume-slider': 'Повзунок звуку',			
			'mejs.video-player': 'Відеоплеєр',
			'mejs.audio-player': 'Аудіоплеєр',			
			'mejs.captions-subtitles': 'Титри/Субтитри',
			'mejs.captions-chapters': 'Глави',
			'mejs.none': 'Немає',
			'mejs.afrikaans': 'Африкаанс',
			'mejs.albanian': 'Албанська',
			'mejs.arabic': 'Арабська',
			'mejs.belarusian': 'Білоруська',
			'mejs.bulgarian': 'Болгарська',
			'mejs.catalan': 'Каталонська',
			'mejs.chinese': 'Китайська',
			'mejs.chinese-simplified': 'Китайська (спрощена)',
			'mejs.chinese-traditional': 'Китайська (традиційна)',
			'mejs.croatian': 'Хорватска',
			'mejs.czech': 'Чеська',
			'mejs.danish': 'Дацька',
			'mejs.dutch': 'Голландська',
			'mejs.english': 'Английська',
			'mejs.estonian': 'Естонська',
			'mejs.filipino': 'Філіппінська',
			'mejs.finnish': 'Фінська',
			'mejs.french': 'Французька',
			'mejs.galician': 'Галісійська',
			'mejs.german': 'Німецька',
			'mejs.greek': 'Грецька',
			'mejs.haitian-creole': 'Гаїтянська креольська',
			'mejs.hebrew': 'Іврит',
			'mejs.hindi': 'Хінді',
			'mejs.hungarian': 'Угорська',
			'mejs.icelandic': 'Ісландська',
			'mejs.indonesian': 'Індонезійська',
			'mejs.irish': 'Ірландська',
			'mejs.italian': 'Італійська',
			'mejs.japanese': 'Японська',
			'mejs.korean': 'Корейська',
			'mejs.latvian': 'Латвійська',
			'mejs.lithuanian': 'Литовська',
			'mejs.macedonian': 'Македонська',
			'mejs.malay': 'Малайська',
			'mejs.maltese': 'Мальтійська',
			'mejs.norwegian': 'Норвезька',
			'mejs.persian': 'Перська',
			'mejs.polish': 'Польська',
			'mejs.portuguese': 'Португальська',
			'mejs.romanian': 'Румунська',
			'mejs.russian': 'Російська',
			'mejs.serbian': 'Сербська',
			'mejs.slovak': 'Словацька',
			'mejs.slovenian': 'Словенська',
			'mejs.spanish': 'Іспанська',
			'mejs.swahili': 'Суахілі',
			'mejs.swedish': 'Шведська',
			'mejs.tagalog': 'Тагальська',
			'mejs.thai': 'Тайська',
			'mejs.turkish': 'Турецька',
			'mejs.ukrainian': 'Українська',
			'mejs.vietnamese': 'В\'єтнамська',
			'mejs.welsh': 'Валлійська',
			'mejs.yiddish': 'Ідиш'
		};
	}
})(mejs.i18n);

$.widget( "custom.quadratize", {

	options: {
	    debug           : false,
		target          : 'div.tile',
        addTarget       : null,
        addCss          : [],
        onInit          : null, //if set , first param is self.element
        onFirstEqualize : null, //if set , first param is self.element
        ignoreBgColor   : true,
        actionEvent     : 'breakpoint.ma'
	},

	_create: function() {
		var self = this;
		if(self.element.data('initialized')){
		    return false;
        }
		self.options.target = this.element.find(self.options.target);
        if($.isFunction(self.options.onInit)){
            self.options.onInit(self.element);
        }
		self._equalize(self.options.onFirstEqualize);

		$(document).on(self.options.actionEvent, function(e){
			self._equalize();
		});
        self.element.data('initialized', true);
	},
    equalize : function(callback){
	    this._equalize(callback);
    },
	_equalize : function(callback){

		var self = this;
		var parent = self.options.target.parent('div:visible');
		var bp = ma.getBreakpoint();
        var addTargets = null;
        var newSetHeight = parent.width();
        var modObj = null;
        if(self.options.addTarget !== null){
            addTargets = self.element.find(self.options.addTarget);
            addTargets.removeAttr('style');
        }
        var targetBackground = self.options.target.css('backgroundColor');
        self.options.target.removeAttr('style');
        if(!self.options.ignoreBgColor){
            self.options.target.css('backgroundColor', targetBackground);
        }

        if(newSetHeight === null){
            //die 60px entsprechen 3 mal gutter-width
            newSetHeight = (($('.container').first().width()-60) / 4);
        }

		if(bp==='xs'){
		    modObj = {
                'min-height' : newSetHeight
            };
            for(var i=0; i<self.options.addCss.length; i++){
                modObj[self.options.addCss[i]] = newSetHeight+'px';
            }
            self.options.target.css(modObj);
            if(self.options.addTarget !== null){
                addTargets.css({
                    'min-height' : newSetHeight
                });
            }
        }else{

            modObj = {
                'height' : newSetHeight
            };
            for(var i=0; i<self.options.addCss.length; i++){
                modObj[self.options.addCss[i]] = newSetHeight+'px';
            }
            self.options.target.css(modObj);
            if(self.options.addTarget !== null){
                addTargets.css({
                    'height' : newSetHeight
                });
            }
        }
        if($.isFunction(callback)){
            callback(self.element);
        }
	}
});
var deviceDetect = function(){
    var device = {};
    this.win = $(window);


    this.identify = function(){
        var ua = navigator.userAgent;

        var found = null;

        var browsers = new Array(
            'firefox', 'chrome', 'opera', 'safari', 'msie'/*Internet explorer until version 10*/,'rv:11.', 'edge'
        );
        var mobiles = [
            'iPhone','iPod','iPad','blackberry','android','Kindle','htc','lg','midp','mmp','mobile','nokia','opera mini',
            'palm','pocket','psp','sgh','smartphone','symbian','treo mini','Playstation Portable','SonyEricsson','Samsung',
            'MobileExplorer','PalmSource','Benq','Windows Phone','Windows Mobile','IEMobile','Windows CE','Nintendo Wii'
        ]
        ua = ua.toLowerCase();
        for(var i = 0; i<browsers.length; i++ ){
            if(ua.search(new RegExp(browsers[i], 'i')) != -1){
                found = browsers[i];
                device['browser'] = browsers[i];
                device['agent'] = ua;

                if(found == 'rv:11.'){
                    device['browser'] = 'msie';
                    device['browserVersion'] = 'ie11';
                    device['isMobile'] = false;
                    device['info'] = 'desktop';
                }
                if(found == 'edge'){
                    device['browser'] = 'msie';
                    device['browserVersion'] = 'ie12';
                }
                if(found == 'msie'){
                    if(ua.match(/MSIE 10/i)){
                        device['browserVersion'] = 'ie10';
                    }
                    if(ua.match(/MSIE 9/i)){
                        device['browserVersion'] = 'ie9';
                    }
                    if(ua.match(/MSIE 8/i)){
                        device['browserVersion'] = 'ie8';
                    }
                    if(ua.match(/MSIE 7/i)){
                        device['browserVersion'] = 'ie7';
                    }
                }
                break;
            }
        }
        found = null;
        for(var i = 0; i<mobiles.length; i++ ){
            if(ua.search(new RegExp(mobiles[i], 'i')) != -1){
                found = mobiles[i];
                device['info'] = mobiles[i];
                device['isMobile'] = true;
                break;
            }
        }
        device['isTouchDevice'] = (typeof(window.ontouchstart)==='undefined' ? false:true);
        device['pixelRatio']    = window.devicePixelRatio;
        device['screenWidth']   = window.screen.width;
        device['screenHeight']  = window.screen.height;
        device['width']         = document.documentElement.clientWidth;
        device['height']        = document.documentElement.clientHeight;
        device['orientation']   = device.getOrientation();
        device.isRetina();

        $(window).resize(function(){
            if(typeof(window.mx_device != 'undefined')){
                window.mx_device['windowwidth']         = document.documentElement.clientWidth;
                window.mx_device['windowheight']        = document.documentElement.clientHeight;
            }

        });
        $.event.trigger({
            type        : 'deviceidentified.dv',
            objInstance : self
        });
    };
    device.getOrientation = function(){
        var ret = null;
        // Find matches
        var mql = window.matchMedia("(orientation: portrait)");
        // If there are matches, we're in portrait
        if(mql.matches) {
            ret = 'portrait';
        }else {
            ret = 'landscape';
        }
        return ret;
    };
    device.getScreenRatio = function(){
        device['screenRatio'] = device['screenWidth'] / device['screenHeight'];
        return device['screenRatio'];
    }

    device.getWindowRatio = function(){
        device['windowRatio'] = device['width'] / device['height'];
        return device['windowRatio'];
    }
    device.isRetina = function(){
        var ret = false;
        if(typeof(window.devicePixelRatio) != 'undefined'){
            if(window.devicePixelRatio > 1){
                device['pixelDepth'] = window.devicePixelRatio;
                ret = true;
            }
        }else if(typeof(window.msMatchMedia) != 'undefined'){
            if(window.msMatchMedia() > 1){
                device['pixelDepth'] = window.msMatchMedia();
                ret = true;
            }
        }else if(typeof(window.matchMedia) != 'undefined'){
            if(window.matchMedia() > 1){
                device['pixelDepth'] = window.matchMedia();
                ret = true;
            }
        }
        device['isRetina'] = ret;
        return ret;
    }
    device.getDevice = function(){
        return device;
    }
    device.generateOutputForProperty = function(propertyname, identifier) {
        var self = this;
        var ptype   = typeof(self[propertyname]);

        var ret     = '';
        if(typeof(identifier) == 'undefined'){
            identifier = propertyname;
        }
        ret += identifier;
        if(ptype != 'undefined'){
            if(ptype == 'null'){
                ret += ': NULL';
            }else{
                if(ptype == 'object'){
                    if($.isArray(self[propertyname])){
                        ret += ': Array [size: '+self[propertyname].length+'] | ' +( self[propertyname].length==0 ? 'empty': self[propertyname].join(',') );
                    }else if($.isPlainObject(self[propertyname])){
                        ret += ': Object | ';
                        for(var e in self[propertyname]){
                            ret += (e + ': '+self[propertyname][e]+' | ');
                        }
                    }
                }else if(ptype == 'function'){
                    var fresult =  'function';
                    if(propertyname != 'generateOutputForProperty' && propertyname != 'htmlOutput'){
                        fresult = self[propertyname]();
                    }
                    ret += ': '+fresult;
                }else{
                    ret += ': ' + self[propertyname]
                }
            }
        }else{
            ret += ': undefined';
        }
        return ret;
    };
    device.htmlOutput = function(){
        var self = this;
        var ret = [];
        for(var e in self){
            ret.push(self.generateOutputForProperty(e));
        }
        return ret.join('<br>');
    }
    this.identify();

    return device.getDevice();
};
/* device objekt direkt speichern :) */
window['mx_device'] = new deviceDetect();
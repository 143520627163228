$.widget( "custom.nav", {
	options: {
		fadetime : 300,
		menuopentime : 100,
        keepOpen : false,
        fullyActivated : true
	},
	_create: function() {
		var self = this;

		self.options.togglers = this.element.find('span.mobMenu');
		self.options.mobileWrap = $('#menu_navmobile');
        self.options.wrapPadding = parseInt(self.options.mobileWrap.css('padding-top'));
        self.options.parentUls = self.element.find('li.haschild ul');
        self.options.TIMEOUT = null;

        //initial closing of all menuitems having children
        self.options.parentUls.fadeToggle(0);

        self.element.find('li.overviewCopy').remove();
        /*if(!ma.getDevice('isTouchDevice')){
            self.element.find('li.overviewCopy').remove();
        }else {
            self.element.find('li.overviewCopy').removeClass('hideOnStart');
        }*/

        //just keep those open, which are representing the actual page
        self.options.actualItem = self.element.find('li.haschild.open').not(':hidden');
        if(self.options.keepOpen){
            self.options.actualItem.find('ul').fadeToggle(0);
        }


        if(self.options.fullyActivated){
            self.element.find('li.haschild').hover(function(e){
                //hovering
                var selectedLi = $(this);
                if(self.options.keepOpen) {
                    if (selectedLi.hasClass('open')) {
                        e.preventDefault();
                        e.stopPropagation();
                        return;
                    }
                }
                self._openSubmenu(selectedLi);
            }, function(e){
                //hovering out
                var selectedLi = $(this);
                if(self.options.keepOpen){
                    if(selectedLi.hasClass('open')){
                        e.preventDefault();
                        e.stopPropagation();
                        return;
                    }
                }
                self._closeSubmenu(selectedLi);
            });
            //klick
            this.element.delegate('li.haschild a', 'click', function(e){
                if(ma.getDevice('isTouchDevice')){
                    e.stopPropagation();
                    var parentLi = $(this).parent('li');
                    var breakpoint = ma.getBreakpoint();

                    if(!parentLi.hasClass('overviewCopy')){
                        if(parentLi.hasClass('open') && parentLi.hasClass('haschild')){
                            e.preventDefault();
                            return;
                        }
                    }

                    if(breakpoint=='lg' || breakpoint=='xl'){
                        if(!parentLi.hasClass('subNavDesktop_2_li')){
                            e.preventDefault();
                        }
                    }else{
                        if(!parentLi.hasClass('subNavMobile_2_li')){
                            e.preventDefault();
                        }
                    }

                    if(parentLi.hasClass('haschild') && parentLi.hasClass('hover')){
                        self._closeSubmenu(parentLi);
                    }else if(parentLi.hasClass('haschild')){
                        self._openSubmenu(parentLi);
                    }
                }
            });
        }

		$(document).on('breakpoint.ma orientation_changed.ma', function(e){
            //mobile nav ausblenden, wenn gerät die mobile nav nich mehr anzeigt
            if(e.status == 'lg' || e.status == 'xl'){
                self.options.mobileWrap.fadeOut(self.options.fadetime);
                $('header').removeClass('mobNavActive');
            }
		})
		
		this._on(self.options.togglers,{click:'_toggleMobileMenu'});
	},
    _setNavHeight: function(){
	    var self = this;
        var heights = [];
        var height = 0;

        heights.push(($(window).height() + 250));
        // heights.push($(document).height());
        // heights.push($('body').height());
        //maximale höhe ermitteln um den screen voll auszufüllen
        height = Math.max.apply(null, heights) - self.options.wrapPadding;
        //ermittelte höhe setzen
	    self.options.mobileWrap.css('min-height', height);
    },
	_toggleMobileMenu:function(){
		var self = this;
		if(self.options.mobileWrap.is(':hidden')){
            self._setNavHeight();
            $('header').addClass('mobNavActive');
			self.options.mobileWrap.fadeIn(self.options.fadetime, function(){
			    $(this).addClass('mobopen');
            });
		}else{
            $('header').removeClass('mobNavActive');
			self.options.mobileWrap.fadeOut(self.options.fadetime, function(){
                $(this).removeClass('mobopen');
            });
		}
	},
    _openSubmenu : function(selectedLi){
        var self = this;
        if(self.options.keepOpen){
            if(self.options.actualItem.find('ul').is(':visible')){
                self._closeSubmenu(self.options.actualItem);
            }
        }
        selectedLi.find('ul').first().stop(true, true).fadeIn(self.options.menuopentime, function(){
            selectedLi.addClass('hover');
        });
    },
    _closeSubmenu : function(selectedLi){
        //if menuitem is open, do anything
        var self = this;
        if(self.options.keepOpen){
            if(self.options.actualItem.find('ul').is(':hidden')){
                self._openSubmenu(self.options.actualItem);
            }
        }
        selectedLi.find('ul').first().stop(true, true).fadeOut(self.options.menuopentime, function(){
            selectedLi.removeClass('hover');
        });
    }
});
$.widget( "custom.teaserbox", {
    options: {
        linkSelector:'a',
        clickSelector:null
    },
    _action:{
        click:false
    },
    _create: function() {
        var self = this;
        if(this.options.clickSelector==null){
            this.options.clickSelector = this.element;
        }else{
            this.options.clickSelector = this.element.find(this.options.clickSelector);
        }

        this.link = this.element.find(this.options.linkSelector);
        if(this.link.length > 0){
            this.options.clickSelector.css('cursor','pointer');
            this._on(this.link,{
                click:'_clickAction',
                mouseup:'_noop',
                touchend:'_noop'
            });
            this._on(this.options.clickSelector,{
                mousedown:'_doAction',
                touchstart:'_doAction',
                mousemove:'_undoAction',
                touchmove:'_undoAction',
                mouseup:'_triggerAction',
                touchend:'_triggerAction'
            });
        }
    },
     _clickAction: function (e) {
        e.stopPropagation();
        return this._action.click;
    },
    _noop : function(e){
        e.stopPropagation();
    },
    _doAction: function (e) {
        this._action.click=true;
    },
    _undoAction: function (e) {
        this._action.click=false;
    },
    _triggerAction: function (e) {
        e.stopPropagation();
        if(e.originalEvent.button == 2){
            return false;
        }
        return this.link.get(0).click();

    }
});
$.widget( "custom.bsEqualizer", {

	options: {
		target : 'div',
		listenon : null,
        preventTimeout : false
	},

	_create: function() {
		var self = this;

		if(self.options.listenon !== null){
            $(document).on(self.options.listenon, function(){
                self._equalize(self.element, self.options.target, self.options.preventTimeout);
			});
		}else{
            self._equalize(self.element, self.options.target, self.options.preventTimeout);
		}
	},
	_equalize : function(objPar, selEqual, preventTimeout){
		var self = this;
		var TIMER = null;
		if(typeof preventTimeout == 'undefined' || typeof preventTimeout == 'null'){
            preventTimeout = false;
		}
		if(objPar.length > 0){
			var eobs = objPar.find(selEqual);
			var colHeights = [];
			eobs.each(function(){
				if(!$(this).hasClass('prevent-'+ma.activeBreakPoint)){
                    colHeights.push($(this).outerHeight(true));
				}

			});
			var maxHeight = Math.max.apply(null, colHeights);

			if(ma.activeBreakPoint !== 'xs'){
                if($(this).hasClass('prevent-'+ma.activeBreakPoint)){
                    eobs.css('height', 'auto');
                }else{
                    eobs.css('height', maxHeight);
				}
			}else{
				eobs.css('height', 'auto');
			}
			//take away set styles from prevent-Objects
            objPar.find('.prevent-'+ma.activeBreakPoint+' div:first-child').css('height', '');

			if(!preventTimeout){
                $(window).on('resize', function(){
                    window.clearTimeout(TIMER);
                    TIMER = window.setTimeout(function(){
                        eobs.css('height', 'auto');
                        self._equalize(objPar, selEqual);
                    }, 50);
                })
			}
		}
	}
});
'use strict';/*!
 * This is a `i18n` language object.
 *
 * Croatian
 *
 * @author
 *   Hrvoj3e (hrvoj3e@gmail.com) * @see core/i18n.js
 */
(function (exports) {
	if (exports.hr === undefined) {
		exports.hr = {
			'mejs.plural-form': 7,			
			'mejs.download-file': 'Preuzmi datoteku',			
			'mejs.install-flash': 'Koristite preglednik koji nema omogućen ili instaliran Flash preglednik. Molimo Vas uključite Flash dodatak ili preuzmite najnoviju verziju s https://get.adobe.com/flashplayer/',			
			'mejs.fullscreen': 'Puni zaslon',			
			'mejs.play': 'Pokreni',
			'mejs.pause': 'Zaustavi',			
			'mejs.time-slider': 'Vremenska traka',
			'mejs.time-help-text': 'Koristi strelice lijevo/desno za pomak naprijed za 1 sekundu te gore/dolje za pomak od 10 sekundi.',
			'mejs.live-broadcast' : 'Prijenos uživo',			
			'mejs.volume-help-text': 'Koristi strelice gore/dolje za pojačavanje ili stišavanje.',
			'mejs.unmute': 'Uključi zvuk',
			'mejs.mute': 'Isključi zvuk',
			'mejs.volume-slider': 'Pokazivač razine zvuka',			
			'mejs.video-player': 'Video preglednik',
			'mejs.audio-player': 'Audio preglednik',			
			'mejs.captions-subtitles': 'Opisi/Prijevodi',
			'mejs.captions-chapters': 'Poglavlja',
			'mejs.none': 'Ništa',
			'mejs.afrikaans': 'Afrički',
			'mejs.albanian': 'Albanski',
			'mejs.arabic': 'Arapski',
			'mejs.belarusian': 'Bjeloruski',
			'mejs.bulgarian': 'Bugarski',
			'mejs.catalan': 'Katalonski',
			'mejs.chinese': 'Kineski',
			'mejs.chinese-simplified': 'Kineski (jednostavni)',
			'mejs.chinese-traditional': 'Kineski (tradicionalni)',
			'mejs.croatian': 'Hrvatski',
			'mejs.czech': 'Češki',
			'mejs.danish': 'Danski',
			'mejs.dutch': 'Nizozemski',
			'mejs.english': 'Engleski',
			'mejs.estonian': 'Estonski',
			'mejs.filipino': 'Filipinski',
			'mejs.finnish': 'Finski',
			'mejs.french': 'Francuski',
			'mejs.galician': 'Galicijski',
			'mejs.german': 'Njemački',
			'mejs.greek': 'Grčki',
			'mejs.haitian-creole': 'Haićanski kreolski',
			'mejs.hebrew': 'Hebrejski',
			'mejs.hindi': 'Hindski',
			'mejs.hungarian': 'Mađarski',
			'mejs.icelandic': 'Islandski',
			'mejs.indonesian': 'Indonezijski',
			'mejs.irish': 'Irski',
			'mejs.italian': 'Talijanski',
			'mejs.japanese': 'Japanski',
			'mejs.korean': 'Korejski',
			'mejs.latvian': 'Latvijski',
			'mejs.lithuanian': 'Litvanski',
			'mejs.macedonian': 'Makedonski',
			'mejs.malay': 'Malajski',
			'mejs.maltese': 'Malteški',
			'mejs.norwegian': 'Norveški',
			'mejs.persian': 'Perzijski',
			'mejs.polish': 'Poljski',
			'mejs.portuguese': 'Portugalski',
			'mejs.romanian': 'Rumunjski',
			'mejs.russian': 'Ruski',
			'mejs.serbian': 'Srpski',
			'mejs.slovak': 'Slovački',
			'mejs.slovenian': 'Slovenski',
			'mejs.spanish': 'Španjolski',
			'mejs.swahili': 'Svahili',
			'mejs.swedish': 'Švedski',
			'mejs.tagalog': 'Tagaloški',
			'mejs.thai': 'Tajski',
			'mejs.turkish': 'Turski',
			'mejs.ukrainian': 'Ukrajinski',
			'mejs.vietnamese': 'Vijetnamski',
			'mejs.welsh': 'Velški',
			'mejs.yiddish': 'Jidiški'
		};
	}
})(mejs.i18n);
$.widget( "custom.adjustHeight", {
    options: {
        'selector': ["h3", ".pintro"]
    },
    _create: function() {
        var self = this;
        this.element.elements = [];

        for(var y=0;y<self.options.selector.length;y++){
            var temparray = [];
            this.element.find(self.options.selector[y]).each(function() {
                temparray.push($(this));
            });
            self.element.elements.push(temparray)
        }
        $(document).on('breakpoint.ma', function(){
            self._reset();
            self._calculateHeights();
        });
        self._calculateHeights();
    },
    _reset: function() {
        for(var y=0;y<this.options.selector.length;y++) {
            this.element.find(this.options.selector[y]).css("height", "auto");
        }
    },
    _calculateHeights: function() {
        for(var y=0;y<this.element.elements.length;y++){
            this.element.height = 0;
            this.currentelement = this.element.elements[y];

            for(var x=0;x<this.currentelement.length;x++){
                if(this.currentelement[x].outerHeight() > this.element.height) {
                    this.element.height = this.currentelement[x].outerHeight();
                }
            }
            this._fixHeight(y,this.element.height);
        }
    },
    _fixHeight: function(selector,height) {
        this.element.find(this.options.selector[selector]).css("height", height);
    }
});
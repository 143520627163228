require("../html/js/src/slick.min.js");
import mobileAction from "../html/js/src/mobileAction";
import proJS from "../html/js/src/project";
require("../html/js/src/jqueryui.teaserbox.js");
require("../html/js/src/jqueryui.tabBar.js");
require("../html/js/src/jqueryui.quadratize.js");
require("../html/js/src/jqueryui.nav.js");
require("../html/js/src/jqueryui.bsEqualizer.js");
require("../html/js/src/jqueryui.adjustHeight.js");
require("../html/js/src/jquery.focuspoint.js");
require("../html/js/src/device.js");
require("../html/js/src/bootstrap-select.min.js");
require("../html/js/src/mediaelement/mediaelement.min.js");
require("../html/js/src/mediaelement/mediaelement.js");
require("../html/js/src/mediaelement/mediaelement-and-player.min.js");
require("../html/js/src/mediaelement/mediaelement-and-player.js");
require("../html/js/src/mediaelement/renderers/vimeo.min.js");
require("../html/js/src/mediaelement/renderers/vimeo.js");
require("../html/js/src/mediaelement/renderers/twitch.min.js");
require("../html/js/src/mediaelement/renderers/twitch.js");
require("../html/js/src/mediaelement/renderers/soundcloud.min.js");
require("../html/js/src/mediaelement/renderers/soundcloud.js");
require("../html/js/src/mediaelement/renderers/facebook.min.js");
require("../html/js/src/mediaelement/renderers/facebook.js");
require("../html/js/src/mediaelement/renderers/dailymotion.min.js");
require("../html/js/src/mediaelement/renderers/dailymotion.js");
require("../html/js/src/mediaelement/lang/zh.js");
require("../html/js/src/mediaelement/lang/zh-cn.js");
require("../html/js/src/mediaelement/lang/uk.js");
require("../html/js/src/mediaelement/lang/sv.js");
require("../html/js/src/mediaelement/lang/sk.js");
require("../html/js/src/mediaelement/lang/ru.js");
require("../html/js/src/mediaelement/lang/ro.js");
require("../html/js/src/mediaelement/lang/pt.js");
require("../html/js/src/mediaelement/lang/pl.js");
require("../html/js/src/mediaelement/lang/nl.js");
require("../html/js/src/mediaelement/lang/ko.js");
require("../html/js/src/mediaelement/lang/ja.js");
require("../html/js/src/mediaelement/lang/it.js");
require("../html/js/src/mediaelement/lang/hu.js");
require("../html/js/src/mediaelement/lang/hr.js");
require("../html/js/src/mediaelement/lang/fr.js");
require("../html/js/src/mediaelement/lang/fa.js");
require("../html/js/src/mediaelement/lang/es.js");
require("../html/js/src/mediaelement/lang/de.js");
require("../html/js/src/mediaelement/lang/cs.js");
require("../html/js/src/mediaelement/lang/ca.js");


window.proJS = proJS;

window.addEventListener("DOMContentLoaded", () => {
    window.ma = new mobileAction($);

    // $('body').addClass('dev');
    $(document).on('breakpoint.ma', function(e){
        if(window.ma.breakpointDown('xl',e)) {
            //debug('breakpoint: '+ e.status, true);
        }
    }).trigger({
        type : 'breakpoint.ma',
        status: window.ma.getBreakpoint()
    });
})

/*
jQuery(document).ready(function($){
    // $('body').addClass('dev');
    $(document).on('breakpoint.ma', function(e){
        if(ma.breakpointDown('xl',e)) {
            //debug('breakpoint: '+ e.status, true);
        }
    }).trigger({
        type : 'breakpoint.ma',
        status: ma.getBreakpoint()
    });
});

 */
;
const proJS = {
    vars : {
        foo: 'bar'
    },
    handleModal : function($, tabParent){
        var modal = $('#modal');
        $(tabParent).delegate('.modalopener', 'click', function(e){
            e.stopPropagation();
            e.preventDefault();
            var clink           = $(this); //clicked Link
            var channel         = clink.data('channel');
            var mData           = clink.data('modal');
            var modalDate       = modal.find('.modal-date');
            var modalHeadline   = modal.find('.modal-title');
            var body            = modal.find('.modal-body');
            var footer          = modal.find('.modal-footer');

            //check mData (json-object in "data-modal")
            if(mData.toString().length != 0){
                if(typeof mData.date != 'undefined'){
                    modalDate.html(mData.date);
                }
                if(typeof mData.headline != 'undefined'){
                    modalHeadline.html(mData.headline);
                }
                if(typeof mData.content != 'undefined'){
                    body.html(mData.content);
                }
                if(typeof mData.footer != 'undefined'){
                    footer.html(mData.footer);
                }
            }

            if(channel === 'ajax'){
                //use link's href for ajax
                $.get(clink.attr('href'), function(data){
                    body.html(data);
                    modal.modal('show');
                });
            }else if(channel === 'hidden'){
                //search in DOM parent for child Element ".hidden"
                body.html(clink.parent().find('.hidden').html());
                modal.modal('show');
            }else{
                modal.modal('show');
            }


        });
    },
    toggleHotels : function($){
        var hotelstab = $('#hotel');
        function setIteaserHeight(){
            var iteaser = hotelstab.find('div.iteaser');
            iteaser.each(function(){
                var thisITeaser = $(this);
                var parent = iteaser.parent('.row');
                parent.css('height', '');
                thisITeaser.css('height', '');
                thisITeaser.height(iteaser.parent('.row').height());
            });

        }

        setIteaserHeight();
        $(document).on('breakpoint.ma', function(e){
            if(e.namespace == 'ma'){
                setIteaserHeight();
            }
        });

        hotelstab.on('click', 'div.infotoggle', function(){
            var activeToggle = $(this);
            var parent = activeToggle.parents('div.hotel');
            var body = parent.find('.hotelbody .toggleCont');
            if(body.hasClass('closed')){
                activeToggle.parent().removeClass('closed').addClass('open');

                body.slideDown(300, function(){
                    activeToggle.removeClass('closed').addClass('open');
                    body.addClass('open').removeClass('closed');
                    hotelstab.quadratize({
                        target : 'div.mobile_iteaser'
                    });
                });
            }else{
                body.slideUp(300, function(){
                    body.removeClass('open').addClass('closed');
                    activeToggle.removeClass('open').addClass('closed');
                    activeToggle.parent().removeClass('open').addClass('closed');
                });
            }
        })
    }
};

export default proJS;